<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row>
      <v-col cols="12" class="mt-5 mb-0">
        <h1 class="font-weight-light mb-2 headline">{{ $t('app.dashboard.quick_actions') }}</h1>
      </v-col>

      <v-col cols="12">
        <v-row>
          <v-col>
            <v-btn
              block
              color="primary"
              @click="addTorneo"
            >
              {{ $t('app.buttons.add') }}
              {{ $t('app.torneos.single') }}
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              block
              color="primary"
              @click="addEquipo"
            >
              {{ $t('app.buttons.add') }}
              {{ $t('app.equipos.single') }}
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              block
              color="primary"
              @click="addJugador"
            >
              {{ $t('app.buttons.add') }}
              {{ $t('app.jugadores.single') }}
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              block
              color="primary"
              @click="addArbitro"
            >
              {{ $t('app.buttons.add') }}
              {{ $t('app.arbitros.single') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <!--<v-col
        cols="12"
        lg="4"
      >
        <base-material-chart-card
          :data="emailsSubscriptionChart.data"
          :options="emailsSubscriptionChart.options"
          :responsive-options="emailsSubscriptionChart.responsiveOptions"
          color="#E91E63"
          hover-reveal
          type="Bar"
        >
          <template v-slot:reveal-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  color="info"
                  icon
                  v-on="on"
                >
                  <v-icon
                    color="info"
                  >
                    mdi-refresh
                  </v-icon>
                </v-btn>
              </template>

              <span>Refresh</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  light
                  icon
                  v-on="on"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>

              <span>Change Date</span>
            </v-tooltip>
          </template>

          <h4 class="card-title font-weight-light mt-2 ml-2">
            Website Views
          </h4>

          <p class="d-inline-flex font-weight-light ml-2 mt-1">
            Last Campaign Performance
          </p>

          <template v-slot:actions>
            <v-icon
              class="mr-1"
              small
            >
              mdi-clock-outline
            </v-icon>
            <span class="caption grey&#45;&#45;text font-weight-light">updated 10 minutes ago</span>
          </template>
        </base-material-chart-card>
      </v-col>

      <v-col
        cols="12"
        lg="4"
      >
        <base-material-chart-card
          :data="dailySalesChart.data"
          :options="dailySalesChart.options"
          color="success"
          hover-reveal
          type="Line"
        >
          <template v-slot:reveal-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  color="info"
                  icon
                  v-on="on"
                >
                  <v-icon
                    color="info"
                  >
                    mdi-refresh
                  </v-icon>
                </v-btn>
              </template>

              <span>Refresh</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  light
                  icon
                  v-on="on"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>

              <span>Change Date</span>
            </v-tooltip>
          </template>

          <h4 class="card-title font-weight-light mt-2 ml-2">
            Daily Sales
          </h4>

          <p class="d-inline-flex font-weight-light ml-2 mt-1">
            <v-icon
              color="green"
              small
            >
              mdi-arrow-up
            </v-icon>
            <span class="green&#45;&#45;text">55%</span>&nbsp;
            increase in today's sales
          </p>

          <template v-slot:actions>
            <v-icon
              class="mr-1"
              small
            >
              mdi-clock-outline
            </v-icon>
            <span class="caption grey&#45;&#45;text font-weight-light">updated 4 minutes ago</span>
          </template>
        </base-material-chart-card>
      </v-col>

      <v-col
        cols="12"
        lg="4"
      >
        <base-material-chart-card
          :data="dataCompletedTasksChart.data"
          :options="dataCompletedTasksChart.options"
          hover-reveal
          color="info"
          type="Line"
        >
          <template v-slot:reveal-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  color="info"
                  icon
                  v-on="on"
                >
                  <v-icon
                    color="info"
                  >
                    mdi-refresh
                  </v-icon>
                </v-btn>
              </template>

              <span>Refresh</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  light
                  icon
                  v-on="on"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>

              <span>Change Date</span>
            </v-tooltip>
          </template>

          <h3 class="card-title font-weight-light mt-2 ml-2">
            Completed Tasks
          </h3>

          <p class="d-inline-flex font-weight-light ml-2 mt-1">
            Last Last Campaign Performance
          </p>

          <template v-slot:actions>
            <v-icon
              class="mr-1"
              small
            >
              mdi-clock-outline
            </v-icon>
            <span class="caption grey&#45;&#45;text font-weight-light">campaign sent 26 minutes ago</span>
          </template>
        </base-material-chart-card>
      </v-col>-->

      <v-col cols="12" class="mt-5 mb-0">
        <h1 class="font-weight-light mb-2 headline">{{ $t('app.dashboard.stats') }}</h1>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="info"
          icon="mdi-tournament"
          :title="`${$t('app.torneos.name')} ${$t('app.general.actives')}`"
          :value="simpleStats.torneos || 0"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="primary"
          icon="mdi-account-group"
          :title="$t('app.equipos.name')"
          :value="simpleStats.equipos || 0"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="success"
          icon="mdi-account"
          :title="$t('app.jugadores.name')"
          :value="simpleStats.jugadores || 0"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="orange"
          icon="mdi-calendar-star"
          :title="$t('app.jornadas.name')"
          :value="simpleStats.jornadas || 0"
        />
      </v-col>
    </v-row>

    <usuarios-add />
    <jugador-add />
    <jugadores-add-to-team-question />
    <jugadores-add-to-team />
    <arbitros-add />
    <equipos-add />
    <equipos-add-to-tournament-question />
    <equipos-add-to-tournament />
    <torneos-add />
  </v-container>
</template>

<script>
import {mapMutations} from 'vuex'
  export default {
    name: 'DashboardDashboard',

    components: {
      UsuariosAdd: () => import('./views/usuarios/UsuariosAdd'),
      JugadorAdd: () => import('./views/jugadores/JugadoresAdd'),
      JugadoresAddToTeamQuestion: () => import('./views/jugadores/JugadoresAddToTeamQuestion'),
      JugadoresAddToTeam: () => import('./views/jugadores/JugadoresAddToTeam'),
      ArbitrosAdd: () => import('./views/arbitros/ArbitrosAdd'),
      TorneosAdd: () => import('./views/torneos/TorneosAdd'),
      EquiposAdd: () => import('./views/equipos/EquiposAdd'),
      EquiposAddToTournamentQuestion: () => import('./views/equipos/EquiposAddToTournamentQuestion'),
      EquiposAddToTournament: () => import('./views/equipos/EquiposAddToTournament')
    },

    data () {
      return {

        simpleStats: {},

        currentDialog: false,

        countryData: {
          US: 2920,
          DE: 1390,
          AU: 760,
          GB: 690,
          RO: 600,
          BR: 550,
        },
        dailySalesChart: {
          data: {
            labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
            series: [
              [12, 17, 7, 17, 23, 18, 38],
            ],
          },
          options: {
            lineSmooth: this.$chartist.Interpolation.cardinal({
              tension: 0,
            }),
            low: 0,
            high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
        },
        dataCompletedTasksChart: {
          data: {
            labels: ['12am', '3pm', '6pm', '9pm', '12pm', '3am', '6am', '9am'],
            series: [
              [230, 750, 450, 300, 280, 240, 200, 190],
            ],
          },
          options: {
            lineSmooth: this.$chartist.Interpolation.cardinal({
              tension: 0,
            }),
            low: 0,
            high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
        },
        emailsSubscriptionChart: {
          data: {
            labels: ['Ja', 'Fe', 'Ma', 'Ap', 'Mai', 'Ju', 'Jul', 'Au', 'Se', 'Oc', 'No', 'De'],
            series: [
              [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],

            ],
          },
          options: {
            axisX: {
              showGrid: false,
            },
            low: 0,
            high: 1000,
            chartPadding: {
              top: 0,
              right: 5,
              bottom: 0,
              left: 0,
            },
          },
          responsiveOptions: [
            ['screen and (max-width: 640px)', {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0]
                },
              },
            }],
          ],
        },
        headers: [
          {
            sortable: false,
            text: 'ID',
            value: 'id',
          },
          {
            sortable: false,
            text: 'Name',
            value: 'name',
          },
          {
            sortable: false,
            text: 'Salary',
            value: 'salary',
            align: 'right',
          },
          {
            sortable: false,
            text: 'Country',
            value: 'country',
            align: 'right',
          },
          {
            sortable: false,
            text: 'City',
            value: 'city',
            align: 'right',
          },
        ],
        items: [
          {
            id: 1,
            name: 'Dakota Rice',
            country: 'Niger',
            city: 'Oud-Tunrhout',
            salary: '$35,738',
          },
          {
            id: 2,
            name: 'Minerva Hooper',
            country: 'Curaçao',
            city: 'Sinaai-Waas',
            salary: '$23,738',
          },
          {
            id: 3,
            name: 'Sage Rodriguez',
            country: 'Netherlands',
            city: 'Overland Park',
            salary: '$56,142',
          },
          {
            id: 4,
            name: 'Philip Chanley',
            country: 'Korea, South',
            city: 'Gloucester',
            salary: '$38,735',
          },
          {
            id: 5,
            name: 'Doris Greene',
            country: 'Malawi',
            city: 'Feldkirchen in Kārnten',
            salary: '$63,542',
          },
        ],
        sales: [
          {
            country: 'USA',
            flag: 'https://demos.creative-tim.com/vue-material-dashboard-pro/img/flags/US.png',
            salesInM: 2920,
          },
          {
            country: 'Germany',
            flag: 'https://demos.creative-tim.com/vue-material-dashboard-pro/img/flags/DE.png',
            salesInM: 1300,
          },
          {
            country: 'Australia',
            flag: 'https://demos.creative-tim.com/vue-material-dashboard-pro/img/flags/AU.png',
            salesInM: 760,
          },
          {
            country: 'United Kingdom',
            flag: 'https://demos.creative-tim.com/vue-material-dashboard-pro/img/flags/GB.png',
            salesInM: 690,
          },
          {
            country: 'Romania',
            flag: 'https://demos.creative-tim.com/vue-material-dashboard-pro/img/flags/RO.png',
            salesInM: 600,
          },
          {
            country: 'Brasil',
            flag: 'https://demos.creative-tim.com/vue-material-dashboard-pro/img/flags/BR.png',
            salesInM: 550,
          },
        ],
        tabs: 0,
        tasks: {
          0: [
            {
              text: 'Sign contract for "What are conference organizers afraid of?"',
              value: true,
            },
            {
              text: 'Lines From Great Russian Literature? Or E-mails From My Boss?',
              value: false,
            },
            {
              text: 'Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit',
              value: false,
            },
            {
              text: 'Create 4 Invisible User Experiences you Never Knew About',
              value: true,
            },
          ],
          1: [
            {
              text: 'Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit',
              value: true,
            },
            {
              text: 'Sign contract for "What are conference organizers afraid of?"',
              value: false,
            },
          ],
          2: [
            {
              text: 'Lines From Great Russian Literature? Or E-mails From My Boss?',
              value: false,
            },
            {
              text: 'Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit',
              value: true,
            },
            {
              text: 'Sign contract for "What are conference organizers afraid of?"',
              value: true,
            },
          ],
        },
        list: {
          0: false,
          1: false,
          2: false,
        },
      }
    },

    computed: {
      totalSales () {
        return this.sales.reduce((acc, val) => acc + val.salesInM, 0)
      },
    },

    async mounted() {
      this.toggleLoader()
      this.simpleStats = await this.getSimpleStats()

      //const usuarios = (await this.getUsuarios()).slice(0, 100)
      const equipos = await this.getEquipos()
      const posiciones = await this.getPosiciones()
      const tipoIdentificacion = await this.getTipoIdentificacion()
      const sucursales = await this.getBranches()
      const estados = await this.getStates()

      this.setAppData({
        //usuarios,
        equipos,
        posiciones,
        tipoIdentificacion,
        sucursales,
        estados,
      })

      this.toggleLoader()

      EventBus.$on('reload-items', user => {
        if (this.currentDialog === 'player') {
          this.currentDialog = false
          EventBus.$emit('jugadores-add', user)
        }

        if (this.currentDialog === 'referee') {
          this.currentDialog = false
          EventBus.$emit('arbitros-add', user)
        }
      })
    },

    methods: {
      ...mapMutations({
        setAppData: 'SET_APP_DATA',
      }),

      complete (index) {
        this.list[index] = !this.list[index]
      },

      async getSimpleStats () {
        let stats = false;

        await this.$http.get(route('v1/stats/simple'))
        .then(response => {
          if (response.body.code === 200) {
            stats = response.body.data
          } else {
            this.processError(response)
          }
        }, error => this.processError(error))

        return stats
      },

      addJugador () {
        this.currentDialog = 'player'
        EventBus.$emit('usuarios-add', true)
      },
      addArbitro () {
        this.currentDialog = 'referee'
        EventBus.$emit('usuarios-add', true)
      },
      addTorneo () {
        EventBus.$emit('torneos-add')
      },
      addEquipo () {
        EventBus.$emit('equipos-add')
      }
    },
  }
</script>
